<template>
  <header class="main-header">
    <div class="container">
      <router-link to="/">
        <img
          alt="Cux Luxus logo"
          src="./assets/cux-luxus-logo.webp"
          class="logo"
        />
      </router-link>
      <div class="booking">
        <button class="button-booking" @click="$router.push('buchung')">
          Jetzt anfragen
        </button>
      </div>
    </div>
  </header>
  <router-view />
  <FooterInformation />
</template>

<script>
import FooterInformation from '@/components/FooterInformation.vue'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/effect-fade'

export default {
  components: {
    FooterInformation
  }
}
</script>

<style lang="scss">
@import "scss/_variables.scss";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=latin-ext&display=swap");

#app {
  font-family: "Montserrat", sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark-blue;
  height: 100%;

  .swiper {
    width: 100%;
    // height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7));
    -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7));
    -webkit-tap-highlight-color: transparent;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    img {
      display: block;
      width: 100%;
    }
  }
  .swiper-pagination-bullet {
    background-color: white;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7));
    -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.7));
  }
  // non-scoped css
  .booking-notification {
    padding: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9) !important;
    .notification-title {
      font-size: 40px;
      font-weight: 300;
      color: $dark-blue;
      letter-spacing: 1.3px;
      padding-bottom: 35px;
    }
    .notification-content {
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 767px) {
    .booking-notification {
      .notification-title {
        font-size: 20px;
        font-weight: 500;
        color: $dark-blue;
        letter-spacing: 1.3px;
      }
    }
  }
}
body {
  margin: 0;
}
html {
  -webkit-tap-highlight-color: transparent;
}
a {
  color: $dark-blue;
}
.button-booking {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border: 1px solid $dark-blue;
  height: 35px;
  padding-left: 25px;
  padding-right: 25px;
  letter-spacing: 0.5px;
  background: white;
  color: $dark-blue;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: $dark-blue;
    color: white;
  }
}
.main-header {
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  // position: relative;
  .container {
    max-width: 1300px;
    height: inherit;
    margin: auto;
    padding: 0px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    height: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .main-header {
    height: 60px;
    .logo {
      height: 12px;
    }
  }
}
</style>
