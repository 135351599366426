<template>
  <div class="footer-container">
    <div class="footer-container-content">
      <div class="footer-item-copyright">
        <router-link to="/"
          >CUX-LUXUS &#8226; Luxus Ferienwohnung in Cuxhaven</router-link
        >
      </div>
      <div class="footer-item-legal">
        <router-link to="/impressum+datenschutz" class="imprint">Impressum & Datenschutz</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../scss/_variables.scss";

a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-size: 14px;
}
.footer-container {
  background-color: $dark-blue;
}
.footer-container-content {
  max-width: 1300px;
  display: flex;
  padding: 20px 30px 20px 30px;
  gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }
}
.footer-item-copyright {
}
.footer-item-legal {
  @media screen and (min-width: $breakpoint-tablet) {
    margin-left: auto;
  }
}
</style>
