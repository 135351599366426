import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/buchung',
    name: 'buchung',
    component: () => import(/* webpackChunkName: "booking" */ '../views/BookingView.vue')
  },
  {
    path: '/impressum+datenschutz',
    name: 'impressum+datenschutz',
    component: () => import(/* webpackChunkName: "imprintprivacy" */ '../views/ImprintPrivacyView.vue')
  },
  {
    path: '/wohnunga',
    name: 'wohnunga',
    component: () => import(/* webpackChunkName: "flata" */ '../views/FlatAView.vue')
  },
  {
    path: '/wohnungb',
    name: 'wohnungb',
    component: () => import(/* webpackChunkName: "flatb" */ '../views/FlatBView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
